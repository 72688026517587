import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css'; 
import Loader from './components/Control/Loader';

const Login = lazy(() => import('./components/Auth/Login'));
const Dashboard = lazy(() => import('./components/Home/Dashboard'));
const Shipment = lazy(() => import('./components/Shipment/List'));
const DeliveryHistory = lazy(() => import('./components/DeliveryHistory/List'));
const HoursSalaryReport = lazy(() => import('./components/Report/HoursSalaryReport'));
const Messengers = lazy(() => import('./components/Messengers/List'));
const Business = lazy(() => import('./components/Business/List'));
const Customers = lazy(() => import('./components/Customers/List'));
const MessengerDetails = lazy(() => import('./components/Courier/Detail'));
const BusinessrDetails = lazy(() => import('./components/Business/Details'));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader loading="true" />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/shipments" element={<Shipment />} />
          <Route path="/delivery-history" element={<DeliveryHistory />} />
          <Route path="/hours-salary-report" element={<HoursSalaryReport />} />
          <Route path="/messengers" element={<Messengers />} />
          <Route path="/business" element={<Business />} /> 
          <Route path="/business-details" element={<BusinessrDetails />} /> 
          <Route path="/customers" element={<Customers />} /> 
          <Route path="/messengers-details" element={<MessengerDetails />} /> 
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
export default App;