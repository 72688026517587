import React, { Component } from 'react';
import { Image } from 'react-bootstrap';  // Add this line
import LoaderGif from '../../assets/images/loader-v2.gif'

const loaderContainer = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "1000",
    backgroundColor: "#F8F8F8AD"
}

const loader = {
    zIndex: "1000",
    position: "absolute"
}

class Loader extends Component {

    render() {
        const { loading, text } = this.props;

        if (!loading) return null;

        return (
            <div style={loaderContainer} className="d-flex justify-content-center align-items-center">
                <div style={loader} className="d-flex flex-column">
                    <div className='d-flex justify-content-center'>
                    <Image src={LoaderGif} style={{ "width": "64px" }} alt="Loading..." />
                    {/* <img src={LoaderGif} style={{ "width": "64px" }} /> */}
                        {/* <span className='fa fa-spin fa-sync fa-3x'></span> */}
                    </div>
                    <div className='text-center mt-4'>
                        <h2>אנא המתן...</h2>
                        {text != null &&
                            <h3>{text}</h3>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Loader;